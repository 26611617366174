.lesson-editor {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .lesson-selector {
    margin-bottom: 20px;
  }
  
  .lesson-selector label {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .lesson-form {
    margin-bottom: 40px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  textarea {
    min-height: 100px;
  }
  
  .quiz-question {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
  }
  
  .generated-code {
    background-color: #333;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: left;
  }
  
  .generated-code pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: monospace;
  }
  