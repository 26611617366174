/* LessonList.css */

.lesson-list-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.lesson-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

.lesson-table th, .lesson-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.lesson-table th {
  background-color: #f2f2f2;
}

.access-button {
  padding: 8px 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.access-button:hover {
  background-color: #45a049;
}

.payment-section {
  margin-top: 20px;
}

.code-input {
  margin-top: 20px;
}

.code-input input {
  padding: 8px;
  width: 200px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.access-code {
  margin-top: 20px;
  color: #0d6efd;
}

.mybutton{
  display: inline-block;
  margin:10px;
}